<template>
  <v-progress-circular v-if="!loaded" indeterminate></v-progress-circular>
  <v-layout v-else class="rounded rounded-md">
    <!--    <settings-main-dialog
      v-if="displaySettings"
      @close="displaySettings = false"
    /> -->

    <v-navigation-drawer id="nav-drawer" v-model="drawer" data-cy="navDrawer">
      <v-list>
        <v-list-item
          data-cy="calendarBtn"
          prepend-icon="$calendar"
          title="Calendrier"
          to="/app/calendar"
        ></v-list-item>
        <v-list-item
          data-cy="statsBtn"
          prepend-icon="$bellCurveCumulative"
          to="/app/stats"
          title="Statistiques"
        ></v-list-item>
        <v-list-item
          v-if="isAdmin"
          data-cy="adminBtn"
          prepend-icon="$gavel"
          title="Admin"
          to="/app/admin"
        ></v-list-item>
      </v-list>

      <template #append>
        <v-list>
          <v-list-item
            data-cy="settingsBtn"
            prepend-icon="$settings"
            title="Paramètres"
            @click="onClickSettings"
          ></v-list-item>
          <v-list-item
            prepend-icon="$logout"
            title="Logout"
            data-cy="logoutBtn"
            @click="onClickLogout"
          ></v-list-item>
        </v-list>

        <div class="version">
          <span :style="'margin-right: 12px;'"> v{{ version }} </span>

          <v-img
            src="/discord.png"
            max-width="15%"
            @click="onClickDiscord"
          ></v-img>
        </div>
      </template>
    </v-navigation-drawer>

    <v-app-bar :title="appNavTitle" color="primary" density="compact">
      <template #prepend>
        <v-btn icon="$menu" data-cy="navDrawerBtn" @click.stop="onClickMenu" />
      </template>
      <!--   <v-spacer />

      <v-btn data-cy="settingBtn" icon="$settings" @click="onClickSettings" />
      <v-btn icon="$logout" data-cy="logoutBtn" @click="onClickLogout" /> -->
    </v-app-bar>
    <v-main>
      <!--    <router-view :key="route.fullPath" v-slot="{ Component }">
        <component :is="Component" />
      </router-view> -->
      <router-view />
    </v-main>
  </v-layout>
</template>

<script setup lang="ts">
//import settingsMainDialog from './Settings/SettingsMainDialog.vue';
import { computed, onBeforeMount, ref } from 'vue';
import { version } from '../version';
import { useRouter } from 'vue-router';
import { useStore } from 'vuex';
import useEmitter from '/imports/ui/EventBus';
import { Tracker } from 'meteor/tracker';
import { Meteor } from 'meteor/meteor';
import { getSettings } from '/imports/api/methods/settings';
//import { Roles } from 'meteor/alanning:roles';

import { ISettings } from '/imports/api/interfaces/settings-interface';
import { getLocations } from '/imports/api/methods/locations';
import { ILocation } from '/imports/api/interfaces/location-interface';

const EventBus = useEmitter();

const router = useRouter();
const store = useStore();

let drawer = ref(true);
let loaded = ref(false);
let isAdmin = ref(false);

let appNavTitle = computed(() => store.state.appNavTitle);

Tracker.autorun(() => {
  isAdmin.value = Roles.userIsInRole(Meteor.userId(), 'admin');
});

onBeforeMount(async () => {
  let settings: ISettings = await getSettings.callAsync({});

  if (settings) {
    store.commit('setSettings', settings);
    EventBus.emit('update:userCalendarSettings');
  }
  let locations: ILocation[] = await getLocations.callAsync({});
  if (locations.length != 0) store.commit('setLocations', locations);
  loaded.value = true;
});

const onClickLogout = () => {
  Meteor.logout(() => {
    router.push('/signin');
  });
};

function onClickDiscord() {
  window.open('https://discord.gg/JEwKkgCgQx', '_blank');
}

function onClickSettings() {
  // if (router.currentRoute.value.path === '/app/calendar/')
  router.push('/app/settings');

  // displaySettings.value = true;
}

function onClickMenu() {
  drawer.value = !drawer.value;
}
</script>

<style>
@media screen and (max-width: 767px) {
  .fc-toolbar.fc-header-toolbar {
    font-size: 60%;
  }
}

.fc .fc-timegrid-slot-label {
  vertical-align: top;
}

.fc-timegrid-slot-label-cushion {
  font-size: 65%;
}
.fc-timegrid-axis-cushion {
  font-size: 60%;
  text-align: center;
}

.my-event-class {
  border-radius: 5px;
}
/* @media (max-width: 767px) {
  .fc-toolbar.fc-header-toolbar {
    display: flex;
    flex-direction: column;
  }
  .fc-toolbar.fc-header-toolbar .fc-toolbar-chunk:nth-child(1) {
    order: 2;
  }
  .fc-toolbar.fc-header-toolbar .fc-toolbar-chunk:nth-child(2) {
    order: 1;
  }
  .fc-toolbar.fc-header-toolbar .fc-toolbar-chunk:nth-child(3) {
    order: 2;
  }
} */
.version {
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 5px;
  margin: 0px;
  width: 100%;
  cursor: pointer;
  display: flex;
  align-items: center;
  bottom: 0;
  background-color: #f0f0f0;
}
</style>
